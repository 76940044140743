import React, {useEffect} from "react";
const RESTAURANTS = require("../restaurants.json");
export const Redirect = () => {
    useEffect(() => {
        const targetKey = "da-ballu";
        const target = Object.keys(RESTAURANTS).filter(restaurantKey => restaurantKey === targetKey).map(restaurantKey => {
            return RESTAURANTS[restaurantKey];
        })[0];
        if (!targetKey) {
            window.location.href = "/";
            return;
        }
        if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
            window.location.href = target.android;
        }
        if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
            window.location.href = target.ios;
        } else {
            window.location.href = target.android;
        }
    }, []);
    return <div>Loading...</div>
}
export default Redirect;
